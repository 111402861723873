exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-in-touch-jsx": () => import("./../../../src/pages/get-in-touch.jsx" /* webpackChunkName: "component---src-pages-get-in-touch-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-work-appliance-world-email-marketing-system-jsx": () => import("./../../../src/pages/my-work/appliance-world/email-marketing-system.jsx" /* webpackChunkName: "component---src-pages-my-work-appliance-world-email-marketing-system-jsx" */),
  "component---src-pages-my-work-appliance-world-price-spider-jsx": () => import("./../../../src/pages/my-work/appliance-world/price-spider.jsx" /* webpackChunkName: "component---src-pages-my-work-appliance-world-price-spider-jsx" */),
  "component---src-pages-my-work-appliance-world-reporting-system-jsx": () => import("./../../../src/pages/my-work/appliance-world/reporting-system.jsx" /* webpackChunkName: "component---src-pages-my-work-appliance-world-reporting-system-jsx" */),
  "component---src-pages-my-work-appliance-world-website-build-jsx": () => import("./../../../src/pages/my-work/appliance-world/website-build.jsx" /* webpackChunkName: "component---src-pages-my-work-appliance-world-website-build-jsx" */),
  "component---src-pages-my-work-glanbia-body-and-fit-jsx": () => import("./../../../src/pages/my-work/glanbia/body-and-fit.jsx" /* webpackChunkName: "component---src-pages-my-work-glanbia-body-and-fit-jsx" */),
  "component---src-pages-my-work-glanbia-optimum-nutrition-jsx": () => import("./../../../src/pages/my-work/glanbia/optimum-nutrition.jsx" /* webpackChunkName: "component---src-pages-my-work-glanbia-optimum-nutrition-jsx" */),
  "component---src-pages-my-work-jsx": () => import("./../../../src/pages/my-work.jsx" /* webpackChunkName: "component---src-pages-my-work-jsx" */),
  "component---src-pages-my-work-oracle-infographics-jsx": () => import("./../../../src/pages/my-work/oracle/infographics.jsx" /* webpackChunkName: "component---src-pages-my-work-oracle-infographics-jsx" */),
  "component---src-pages-my-work-oracle-internship-experience-jsx": () => import("./../../../src/pages/my-work/oracle/internship-experience.jsx" /* webpackChunkName: "component---src-pages-my-work-oracle-internship-experience-jsx" */),
  "component---src-pages-my-work-oracle-journey-to-the-cloud-jsx": () => import("./../../../src/pages/my-work/oracle/journey-to-the-cloud.jsx" /* webpackChunkName: "component---src-pages-my-work-oracle-journey-to-the-cloud-jsx" */),
  "component---src-pages-my-work-quest-partnership-ecommerce-website-build-jsx": () => import("./../../../src/pages/my-work/quest-partnership/ecommerce-website-build.jsx" /* webpackChunkName: "component---src-pages-my-work-quest-partnership-ecommerce-website-build-jsx" */),
  "component---src-pages-my-work-sage-payroll-solvers-jsx": () => import("./../../../src/pages/my-work/sage/payroll-solvers.jsx" /* webpackChunkName: "component---src-pages-my-work-sage-payroll-solvers-jsx" */),
  "component---src-pages-my-work-sopra-steria-change-realists-jsx": () => import("./../../../src/pages/my-work/sopra-steria/change-realists.jsx" /* webpackChunkName: "component---src-pages-my-work-sopra-steria-change-realists-jsx" */)
}

